<template>
  <div class="flex flex-col p-5 mt-8 body-text-1 text-gray-700">
    <div v-if="isPayPalSubscription">
      <p class="body-text-4">
        Please contact our customer service to make adjustments to your monthly
        donation.
      </p>
      <p class="body-text-4">
        Samantha Parker
        <a
          class="text-brand-navy underline underline-offset-2 cursor-pointer visited:text-brand-navy"
          href="tel:+1-866-731-4862;ext=110"
        >
          (866) 731-4862 x110
        </a>
      </p>
    </div>
    <span class="body-text-4" v-else>{{ error }}</span>
  </div>
</template>

<script>
import { PAGES } from '@/constants/externalPages'

export default {
  name: 'SubscriptionEditFailed',
  props: {
    error: {
      type: String,
      default: ''
    },
    subscription: {
      type: Object,
      required: true
    }
  },
  computed: {
    isPayPalSubscription() {
      return this.subscription?.method === 'paypal'
    }
  },
  methods: {
    handleContactUs() {
      return PAGES?.contact
        ? window.open(PAGES?.contact, '_blank', 'noreferrer')
        : undefined
    }
  }
}
</script>
