<template>
  <div class="relative min-h-screen pt-4">
    <div class="flex flex-col max-w-[630px] mx-auto px-5 md:px-0">
      <div class="flex flex-col">
        <BaseIcon
          name="arrow-left"
          class="w-6 h-6 text-brand-navy cursor-pointer -ml-0.5 -mt-1.5"
          @click="handleDismiss"
        />
        <h4 class="text-brand-navy mt-2.5">Subscription Details</h4>
      </div>
      <div class="bg-white rounded-lg overflow-hidden mt-5">
        <div class="w-full h-6 bg-secondary-light-blue"></div>
        <div class="flex flex-col p-5 pt-10 pb-[30px] md:p-10 md:pb-[30px]">
          <h4 class="text-brand-navy">{{ header }}</h4>
          <div class="body-text-1 text-gray-700">
            Subscribed Since {{ subscribedSince }}
          </div>
          <div class="flex gap-4 mt-[30px]">
            <div class="flex flex-1 min-w-[126px]">
              <span class="subtitle-3 text-brand-navy">Payment Amount</span>
            </div>
            <div class="flex flex-1">
              <span class="body-text-1 text-gray-700">
                {{ amount }}/Month
              </span>
            </div>
            <div class="flex flex-0 justify-end min-w-[70px]">
              <span
                class="body-text-1 text-brand-navy underline underline-offset-4 cursor-pointer"
                @click="handleAmountChange"
              >
                Edit
              </span>
            </div>
          </div>
          <div class="flex gap-4 mt-5">
            <div class="flex flex-1 min-w-[126px]">
              <span class="subtitle-3 text-brand-navy">Payment Method</span>
            </div>
            <div class="flex flex-1 w-[50px]">
              <span
                class="body-text-1 text-gray-700 text-ellipsis overflow-hidden"
              >
                {{ paymentMethodUpdated ? newPaymentDetails : paymentDetails }}
              </span>
            </div>
            <div class="flex flex-0 justify-end min-w-[70px]">
              <span
                class="body-text-1 text-brand-navy underline underline-offset-4 cursor-pointer"
                @click="handlePaymentMethodChange"
                v-if="canChangePaymentMethod"
              >
                Edit
              </span>
            </div>
          </div>
          <div class="flex gap-4 mt-5">
            <div class="flex flex-1 min-w-[126px]">
              <span class="subtitle-3 text-brand-navy">Next Billing</span>
            </div>
            <div class="flex flex-1">
              <span class="body-text-1 text-gray-700">
                {{ nextBilling }}
              </span>
            </div>
            <div class="flex flex-0 justify-end min-w-[70px]">
              <span class="body-text-1 text-gray-700">
                {{ amount }}
              </span>
            </div>
          </div>
          <div class="flex gap-4 mt-5">
            <div class="flex flex-1 min-w-[126px]">
              <span class="subtitle-3 text-brand-navy">Last Payment</span>
            </div>
            <div class="flex flex-1">
              <span class="body-text-1 text-gray-700">
                {{ lastPayment }}
              </span>
            </div>
            <div class="flex flex-0 justify-end min-w-[70px]">
              <span class="body-text-1 text-gray-700">
                {{ lastPaymentAmount }}
              </span>
            </div>
          </div>
          <div class="flex mt-[48px] md:mt-5 justify-center items-center">
            <BaseButton
              id="cancel"
              variant="underline"
              class="uppercase max-w-max min-h-full hover:text-brand-navy"
              @click="handleCancel"
            >
              Cancel Subscription
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BaseBottomSheet v-show="showEditSubscription">
    <SubscriptionEdit
      v-if="showEditSubscription"
      @back-to-subscription="handleBackToSubscription"
      @back-to-dashboard="handleDismiss"
      :subscription="currentSubscription"
    />
  </BaseBottomSheet>
  <BaseBottomSheet v-show="showCancelSubscription">
    <SubscriptionCancel
      v-if="showCancelSubscription"
      :subscription="subscription"
      @back-to-subscription="handleBackToSubscription"
      @back-to-dashboard="handleDismiss"
    />
  </BaseBottomSheet>
  <BaseBottomSheet v-show="showPaymentMethod">
    <SubscriptionPayment
      v-if="showPaymentMethod"
      :subscription="subscription"
      @back-to-subscription="handleBackToSubscription"
      @back-to-dashboard="handleDismiss"
    />
  </BaseBottomSheet>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import SubscriptionEdit from '@/components/Subscription/SubscriptionEdit/SubscriptionEdit.vue'
import SubscriptionCancel from '@/components/Subscription/SubscriptionCancel/SubscriptionCancel.vue'
import { numericOnly } from '@/directives/numericOnly'
import { mask } from 'vue-the-mask'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import currency from '@/mixins/currency'
import BaseBottomSheet from '@/components/Base/BaseBottomSheet.vue'
import date from '@/mixins/date'
import escapeHandler from '@/mixins/escapeHandler'
import dayjs from 'dayjs'
import SubscriptionPayment from '@/components/Subscription/SubscriptionPayment/SubscriptionPayment.vue'
import scrollTop from '@/mixins/scrollTop'

function initialState() {
  return {
    showEditSubscription: false,
    showCancelSubscription: false,
    currentSubscription: null,
    subscriptionUpdated: false,
    showPaymentMethod: false
  }
}

export default {
  name: 'SubscriptionDetails',
  directives: {
    numericOnly,
    mask
  },
  mixins: [currency, date, escapeHandler, scrollTop],
  components: {
    SubscriptionPayment,
    BaseButton,
    SubscriptionEdit,
    SubscriptionCancel,
    BaseIcon,
    BaseBottomSheet
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    editSubscription: {
      type: Boolean,
      default: false
    },
    cancelSubscription: {
      type: Boolean,
      default: false
    },
    onDismiss: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      ...initialState(),
      lastPaymentValue: this.subscription?.amount || 0,
      paymentMethodUpdated: false,
      newPaymentDetails: ''
    }
  },
  mounted() {
    this.scrollTop()
  },
  created() {
    this.escapeHandler(this.handleDismiss)

    this.emitter.on('subscription-edit_updated', ({ amount }) => {
      if (this.currentSubscription?.amount) {
        this.currentSubscription.amount = amount
      }

      if (this.currentSubscription?.future_amount) {
        this.currentSubscription.future_amount = amount
      }

      this.subscriptionUpdated = true
    })

    this.emitter.on('back-to-subscription', ({ reload }) => {
      this.scrollTop()
      this.showEditSubscription = false
      this.showCancelSubscription = false
      this.showPaymentMethod = false
      this.subscriptionUpdated = reload
    })

    this.emitter.on('reload-subscription', ({ card }) => {
      this.scrollTop()
      const { last4, brand } = card?.default_source_card_info || {}
      this.showEditSubscription = false
      this.showCancelSubscription = false
      this.showPaymentMethod = false
      this.subscriptionUpdated = true
      this.paymentMethodUpdated = true
      this.newPaymentDetails = last4 && brand ? `${brand} *${last4}` : ''
    })
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState())
    },
    handleCancel() {
      this.showCancelSubscription = true
      this.showEditSubscription = false
    },
    handleAmountChange() {
      this.currentSubscription = this.subscription
      this.showEditSubscription = true
    },
    handleBackToSubscription() {
      this.showEditSubscription = false
      this.showCancelSubscription = false
      this.currentSubscription = null
    },
    handleDismiss() {
      this.$store.commit('setIsSubscriptionDetailsOpen', false)
      this.$emit('dismiss')
      this.emitter.emit('subscriptions-refresh', {
        reload: this.subscriptionUpdated
      })
    },
    handlePaymentMethodChange() {
      this.showPaymentMethod = true
      this.currentSubscription = this.subscription
    }
  },
  watch: {
    '$store.state.isSubscriptionDetailsOpen': function (value) {
      if (!value) {
        this.handleDismiss()
      }
    }
  },
  computed: {
    amount() {
      let amount = this.subscription?.amount

      if (this.subscription?.method === 'stripe') {
        amount = this.subscription?.stripe_amount
          ? this.subscription.stripe_amount / 100
          : this.subscription?.amount
      }

      if (this.subscriptionUpdated) {
        amount =
          this.currentSubscription?.amount + 2.99 ||
          this.currentSubscription?.future_amount
      }

      if (isNaN(amount)) {
        amount = 0
      }

      return this.formatCurrency(Math.floor(amount), { decimals: false })
    },
    subscribedSince() {
      return this.formatDate(
        this.subscription.subscription_start,
        'MMM DD, YYYY'
      )
    },
    nextBilling() {
      return (
        this.subscription?.current_period_end ??
        this.formatDate(
          this.setDate(this.subscription.current_period_start, {
            unit: 'month',
            amount: 1
          }),
          'MMM DD, YYYY'
        )
      )
    },
    lastPayment() {
      if (this.isFuturePayment) {
        return 'N/A'
      }

      return this.subscription.current_period_start ===
        this.subscription.current_period_end
        ? 'N/A'
        : this.subscription.current_period_start
    },
    lastPaymentAmount() {
      if (this.isFuturePayment) {
        return 'N/A'
      }

      return this.subscription.current_period_start ===
        this.subscription.current_period_end
        ? ''
        : this.formatCurrency(Math.floor(this.lastPaymentValue), {
            decimals: false
          })
    },
    header() {
      return this.subscription.type === 'fullbowls'
        ? 'Full Bowls Club'
        : 'CUDDLY Club'
    },
    paymentDetails() {
      return this.subscription?.method === 'paypal'
        ? this.subscription?.email
        : this.stripeCardDetails
    },
    stripeCardDetails() {
      return this.subscription?.last4 === 'N/A'
        ? 'N/A'
        : `${this.subscription?.brand} *${this.subscription?.last4}`
    },
    isFuturePayment() {
      return dayjs(this.subscription.subscription_start).isAfter(dayjs(), 'day')
    },
    canChangePaymentMethod() {
      return this.subscription?.method === 'stripe'
    }
  },
  unmounted() {
    this.emitter.off('subscription-edit_updated')
    this.emitter.emit('subscriptions-refresh', {
      reload: this.subscriptionUpdated
    })
  }
}
</script>
