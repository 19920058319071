<template>
  <Transition name="subscription" appear>
    <div>
      <div class="flex flex-col bg-white rounded-lg shadow-1">
        <div
          class="p-5 pb-2.5 min-h-[84px] rounded-t-lg bg-secondary-light-blue"
        >
          <div class="relative h-full">
            <div class="w-full flex gap-6">
              <h6 class="text-brand-navy">
                {{ cardHeader }}
              </h6>
              <div>
                <button
                  :data-testid="editButtonId"
                  class="absolute top-0 -right-1 h-6 w-6"
                  @click="handleEdit"
                >
                  <BaseIcon name="pencil-alt" class="text-brand-navy" />
                </button>
              </div>
            </div>
            <span class="body-text-4 mt-[6px]">
              {{ subHeader }}
            </span>
          </div>
        </div>
        <div class="flex flex-col w-full p-5 pt-2.5 relative">
          <div class="flex justify-between">
            <div class="flex items-center">
              <h3 class="font-extrabold leading-45 text-brand-navy">
                {{ monthlyAmount }}
              </h3>
              <span class="body-text-1 text-gray-700 ml-1">Monthly</span>
            </div>
          </div>
          <div class="flex mt-3 justify-between">
            <div class="flex flex-1 min-w-[134px]">
              <span class="subtitle-2 text-brand-navy">Payment Method</span>
            </div>
            <div class="text-ellipsis overflow-hidden text-right">
              <span class="body-text-4 text-gray-700 whitespace-nowrap">
                {{ paymentDetails }}
              </span>
            </div>
          </div>
          <div class="flex justify-between items-center mt-3">
            <span class="flex-1 subtitle-2 text-brand-navy">Last Payment</span>
            <span
              class="flex-1 text-right pr-2.5 body-text-4 text-gray-700 max-w-[110px] min-w-[78px]"
            >
              {{ lastPayment }}
            </span>
            <span class="body-text-4 text-gray-700">
              {{ lastPaymentAmount }}
            </span>
          </div>
          <div class="flex justify-between items-center mt-3">
            <span class="flex-1 subtitle-2 text-brand-navy">Next Payment</span>
            <span
              class="flex-1 text-right pr-2.5 body-text-4 text-gray-700 max-w-[110px] min-w-[78px]"
            >
              {{ nextPayment }}
            </span>
            <span class="body-text-4 text-gray-700">
              {{ nextPaymentAmount }}
            </span>
          </div>
          <div v-if="isPaused">
            <BaseButton
              :id="reactivateButtonId"
              variant="outlined"
              class="mt-5 py-[15px] tracking-2"
              @click="handleReactivate"
            >
              Reactivate Now
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import currency from '@/mixins/currency'
import string from '@/mixins/string'
import date from '@/mixins/date'
import BaseButton from '@/components/Base/BaseButton.vue'
import dayjs from 'dayjs'

export default {
  name: 'SubscriptionCard',
  components: {
    BaseButton,
    BaseIcon
  },
  mixins: [currency, string, date],
  props: {
    subscription: {
      type: Object,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onReactivate: {
      type: Function,
      required: true
    }
  },
  methods: {
    handleEdit() {
      this.$store.commit('setIsSubscriptionDetailsOpen', true)
      this.onEdit(this.subscription)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleReactivate() {
      this.$store.commit('setIsSubscriptionReactivateOpen', true)
      this.onReactivate(this.subscription)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    editButtonId() {
      return `edit-subscription-${this.subscription.id}`
    },
    reactivateButtonId() {
      return `reactivate-subscription-${this.subscription.id}`
    },
    subscriptionType: function () {
      const types = {
        wishpack: {
          title: 'CUDDLY Club',
          hasHeader: false
        },
        fullbowls: {
          title: 'Full Bowls',
          hasHeader: true
        }
      }

      return types[this.subscription.type] || types.fullbowls
    },
    monthlyAmount() {
      if (this.subscription?.subscription_status === 'paused') {
        return this.formatCurrency(0, { decimals: false })
      } else {
        let amount = this.subscription?.amount

        if (this.subscription?.method === 'stripe') {
          amount = this.subscription?.stripe_amount
            ? this.subscription.stripe_amount / 100
            : this.subscription?.amount
        }

        return this.formatCurrency(
          Math.floor(amount || this.subscription?.future_amount || 0),
          { decimals: false }
        )
      }
    },
    amount() {
      return this.formatCurrency(
        Math.floor(
          this.subscription?.amount || this.subscription?.future_amount || 0
        ),
        { decimals: false }
      )
    },
    nextPaymentAmount() {
      let amount = this.subscription?.amount

      if (this.subscription?.method === 'stripe') {
        amount = this.subscription?.stripe_amount
          ? this.subscription.stripe_amount / 100
          : this.subscription?.amount
      }

      return this.formatCurrency(
        Math.floor(amount || this.subscription?.future_amount || 0),
        { decimals: false }
      )
    },
    subscribedSince() {
      return this.formatDate(
        this.subscription.subscription_start,
        'MMM DD, YYYY'
      )
    },
    nextPayment() {
      if (
        this.subscription?.subscription_status === 'paused' &&
        this.subscription?.method === 'paypal'
      ) {
        return 'N/A'
      }

      return (
        this.subscription?.current_period_end ??
        this.formatDate(
          this.setDate(this.subscription.current_period_start, {
            unit: 'month',
            amount: 1
          }),
          'MMM DD, YYYY'
        )
      )
    },
    lastPayment() {
      if (this.isFuturePayment) {
        return 'N/A'
      }

      return this.subscription.current_period_start ===
        this.subscription.current_period_end
        ? 'N/A'
        : this.subscription.current_period_start
    },
    lastPaymentAmount() {
      if (this.isFuturePayment) {
        return 'N/A'
      }

      return this.subscription.current_period_start ===
        this.subscription.current_period_end
        ? ''
        : this.amount
    },
    paymentDetails() {
      return this.subscription?.method === 'paypal'
        ? this.subscription?.email
        : this.stripeCardDetails
    },
    stripeCardDetails() {
      return this.subscription?.last4 === 'N/A'
        ? 'N/A'
        : `${this.subscription?.brand} *${this.subscription?.last4}`
    },
    cardHeader() {
      return this.subscriptionType.hasHeader
        ? this.subscription.name
        : this.subscriptionType.title
    },
    isPaused() {
      return this.subscription.subscription_status === 'paused'
    },
    subHeader() {
      const pausedMessage =
        this.isPaused && this.subscription?.paused_months
          ? `Paused for ${this.subscription.paused_months} ${
              this.subscription.paused_months > 1 ? 'months' : 'month'
            }`
          : 'Paused'

      return this.isPaused
        ? pausedMessage
        : `Subscribed since ${this.subscribedSince}`
    },
    isFuturePayment() {
      return dayjs(this.subscription.subscription_start).isAfter(dayjs(), 'day')
    }
  },
  unmounted() {
    this.$store.commit('setIsSubscriptionDetailsOpen', false)
  }
}
</script>

<style scoped>
.subscription-enter-active {
  animation: fadeIn 4s ease-in both;
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 0%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
