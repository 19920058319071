<template>
  <div class="flex flex-col p-5 mt-5">
    <h4 class="text-brand-navy font-extrabold text-[30px] leading-45">
      Your Current {{ subscriptionType }} Subscription is
      <span class="text-brand-pink">{{ currentAmount }}</span
      >.
    </h4>
    <SubscriptionMonthlyAmounts :updateAmount="updateAmount" />
    <div
      class="absolute left-0 right-0 bottom-28 md:bottom-10 flex flex-col w-full px-5 md:px-[26px] items-center"
    >
      <BaseButton
        id="save"
        class="py-[14px] flex-1 tracking-1"
        @click="handleSave"
        :loading="loading"
        :disabled="loading"
      >
        Update Monthly Amount
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import SubscriptionMonthlyAmounts from '@/components/Subscription/SubscriptionMonthlyAmounts.vue'
import { updateDonorSubscription } from '@/api/subscriptions'
import currency from '@/mixins/currency'

export default {
  name: 'SubscriptionEditDefault',
  mixins: [currency],
  components: { SubscriptionMonthlyAmounts, BaseButton },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    subscriptionType: {
      type: String,
      required: true
    }
  },
  data() {
    const amount = this.formatCurrency(
      Math.floor(
        this.subscription?.amount || this.subscription?.future_amount || 0
      ),
      { decimals: false }
    )

    return {
      currentAmount: amount,
      selectedAmount: null,
      customAmount: null,
      loading: false
    }
  },
  mounted() {
    if (this.subscription.method === 'paypal') {
      this.emitter.emit('subscription-edit_failed', { error: '' })
    }
  },
  methods: {
    handleBack() {
      this.emitter.emit('back-to-subscription', { reload: false })
    },
    async handleSave() {
      this.loading = true

      if (this.customAmount) {
        this.selectedAmount = Number(this.customAmount.replace('$', ''))
      }

      if (!this.selectedAmount) {
        this.$toast.error(
          'Please select an amount or enter a custom amount to continue.'
        )
        this.loading = false

        return
      }

      this.selectedAmount = this.selectedAmount * 100
      const payload = {
        subscriptionId: this.subscription.subscription_id,
        amount: this.selectedAmount
      }

      let error = 'The subscription amount cannot be changed right now.'

      if (this.subscription.method === 'paypal') {
        this.emitter.emit('subscription-edit_failed', { error })
      } else {
        const { status } = await updateDonorSubscription(payload)

        if (status !== 200) {
          error =
            status === 422
              ? 'The subscription amount cannot be changed right now.'
              : 'Unfortunately we have experienced an error. Please try again later.'
          this.emitter.emit('subscription-edit_failed', { error })
          this.$toast.error('Whoops, looks like something went wrong.')
        } else {
          this.emitter.emit('subscription-edit_updated', {
            amount: this.selectedAmount / 100
          })
        }
      }

      this.loading = false
    },
    updateAmount(amount) {
      this.selectedAmount = amount
    }
  }
}
</script>
